<template>
  <g :fill="color" fill-rule="nonzero">
    <path
      d="M3.417 5.088v6.923c0 .87.71 1.572 1.582 1.572h8.994c.873 0 1.583-.702 1.583-1.572V5.088c0-.87-.71-1.572-1.583-1.572H5c-.872 0-1.582.702-1.582 1.572zm1.166 6.923V5.088A.41.41 0 0 1 5 4.682h8.994a.41.41 0 0 1 .416.406v6.923a.41.41 0 0 1-.416.406H5a.41.41 0 0 1-.416-.406z"
    />
    <path
      d="M11 7.472a.583.583 0 0 1 .094 1.159L11 8.639H8a.583.583 0 0 1-.095-1.16L8 7.473h3zM.417 2.12C.417 1.3 1.05.628 1.855.556L2 .549h9.994a.583.583 0 0 1 .094 1.159l-.094.007H1.999c-.202 0-.369.14-.407.324l-.009.082v7.912a.583.583 0 0 1-1.159.095l-.007-.095V2.121z"
    />
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
